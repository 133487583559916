import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import DailyPromo from "./model/DailyPromo";
import Promo, { YOUBI } from "./model/Promo";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import styles from './SubChart.module.css';
import hex2rgba from "./thank/yoheim";

const chartMaxPayYen = 600000;

export default function SubChart(
  {
    dailyPromo
  }: {
    dailyPromo: DailyPromo | undefined
  }) {

  if (!dailyPromo) {
    return (
      <div style={{ width: '100%', height: '800px', textAlign: 'center', fontSize: '2rem' }}>
        上のグラフをクリックしてね。<br />クリックした日の情報をココに表示するよ。
      </div>
    );
  }

  // maxPayYenの降順に並べ替える。
  dailyPromo.promoArray.sort((p1, p2) => p2.maxPayYen - p1.maxPayYen);

  const promoArray = dailyPromo.promoArray;
  if (!promoArray || promoArray.length === 0) {
    return null;
  }
  let payYenArray = getPayYenArray(promoArray);

  const percentChartData = createPercentChartData(payYenArray);
  const pointChartData = createPointChartData(payYenArray);

  let chartMaxPoint = pointChartData.find(d => d.payYen === chartMaxPayYen).sumPoint;
  chartMaxPoint = Math.ceil(chartMaxPoint / 10000) * 10000;

  const date = (dailyPromo.date.getMonth() + 1) + '/' + dailyPromo.date.getDate() + '(' + YOUBI[dailyPromo.date.getDay()] + ')';

  console.debug('SubChart render.');
  return (
    <>
      <div className={styles.graphName}>上限ポイント到達 グラフ {date}</div>
      <div style={{ width: '100%', height: '400px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={percentChartData}
            syncId="syncId1"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="payYen"
              type="number"
              allowDecimals={false}
              allowDataOverflow={true}
              domain={[0, chartMaxPayYen]}
              label={{ value: '支払い額(税抜)', position: 'insideBottom', offset: -4 }}
              tickFormatter={value => value.toLocaleString() + '円'}
            />
            <YAxis
              type="number"
              label={{ value: 'ポイント還元率', angle: -90, position: 'insideLeft' }}
              tickFormatter={value => `${value}%`}
              allowDecimals={false}
              tickCount={6}
            />
            {dailyPromo.promoArray.map((p, i) =>
              <Area
                key={p.id}
                dataKey={p.name}
                stroke={p.color}
                fill={p.color}
                strokeOpacity={p.confirmed ? 1 : 0.2}
                fillOpacity={p.confirmed ? 1 : 0.2}
                stackId="1"
              />
            )}
            <Tooltip content={<PercentTooltip />} />
          </AreaChart>
        </ResponsiveContainer>
      </div>

      <div className={styles.graphName}>獲得ポイント グラフ {date}</div>
      <div style={{ width: '100%', height: '400px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={pointChartData}
            syncId="syncId1"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="payYen"
              type="number"
              allowDecimals={false}
              allowDataOverflow={true}
              domain={[0, chartMaxPayYen]}
              label={{ value: '支払い額(税抜)', position: 'insideBottom', offset: -4 }}
              tickFormatter={value => value.toLocaleString() + '円'}
            />
            <YAxis
              type="number"
              allowDecimals={false}
              allowDataOverflow={true}
              domain={[0, chartMaxPoint]}
              label={{ value: '獲得ポイント数', angle: -90, position: 'insideLeft' }}
              tickFormatter={value => value.toLocaleString() + '\n㌽'}
              tickCount={6}
            />
            {dailyPromo.promoArray.map((p, i) =>
              <Area
                key={p.id}
                dataKey={p.name}
                stroke={p.color}
                fill={p.color}
                strokeOpacity={p.confirmed ? 1 : 0.2}
                fillOpacity={p.confirmed ? 1 : 0.2}
                stackId="1"
              />
            ).reverse()}
            <Tooltip content={<PointTooltip />} />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </>
  );

  function getPayYenArray(promoArray: Promo[]): number[] {
    let payYenArray = promoArray.map(p => p.maxPayYen);
    for (let i = 0; true; i++) {
      const yen = i * 1000;
      if (chartMaxPayYen < yen) {
        break;
      } else {
        payYenArray.push(yen);
      }
    }
    payYenArray.push(chartMaxPayYen);

    payYenArray = Array.from(new Set(payYenArray));
    payYenArray.sort((y1, y2) => y1 - y2);
    return payYenArray;
  }

  function createPercentChartData(payYenArray: number[]): any[] {
    const chartData = payYenArray.map(payYen => {
      const keyValueArray = promoArray.filter(p => payYen <= p.maxPayYen).map(p => [p.name, p.pointPercent]);
      const keyValueObj = Object.fromEntries(keyValueArray);
      return { payYen, ...keyValueObj }
    });
    return chartData;
  }

  function createPointChartData(payYenArray: number[]): any[] {
    const chartData = payYenArray.map(payYen => {
      const sumPoint = promoArray.map(p => payYen < p.maxPayYen ? Math.floor(p.pointPercent / 100 * payYen) : p.pointMax).reduce((pre, cur) => pre + cur);

      const keyValueArray = promoArray.map(p => [p.name, p.maxPayYen <= payYen ? p.pointMax : payYen * p.pointPercent / 100]);
      const keyValueObj = Object.fromEntries(keyValueArray);
      return { payYen, ...keyValueObj, sumPoint } // sumPointはグラフ表示用ではなく、計算用
    });
    return chartData;
  }

  function PercentTooltip({ active, payload, label }: TooltipProps<ValueType, NameType>) {
    if (!active || !payload || !payload.length || !label || !dailyPromo) {
      return null;
    }

    const payYen = Number(label);
    const promoObjArray = payload.map(a => ({ name: a.dataKey, pointPercent: Number(a.value), color: a.stroke, confirmed: true })); // confirmed は後で設定する。
    promoObjArray.reverse();
    promoObjArray.forEach(pObj => {
      const promo = dailyPromo.promoArray.find(p => p.name === pObj.name);
      if (promo) {
        pObj['confirmed'] = promo.confirmed;
      }
    });

    const sumPointPercent = promoObjArray.reduce((sum: number, p: { pointPercent: number }) => { return sum + p.pointPercent }, 0);

    return (
      <div className={styles.tooltip}>
        <p className={styles.sum}>{sumPointPercent.toFixed(1)}% {payYen.toLocaleString()}円支払い時点</p>
        <hr className={styles.tooltipHr} />
        内訳
        {promoObjArray.map(p =>
          <p style={{ backgroundColor: p.confirmed ? p.color : hex2rgba(p.color, 0.2) }} key={p.name}>
            {p.pointPercent.toFixed(1)}% {p.confirmed ? '' : '【未確定】'}{p.name}
          </p>
        )}
      </div>
    );
  }

  function PointTooltip({ active, payload, label }: TooltipProps<ValueType, NameType>) {
    if (!active || !payload || !payload.length || !label || !dailyPromo) {
      return null;
    }

    const payYen = Number(label);

    const promoObjArray = dailyPromo.promoArray.map(p => ({
      promo: p,
      point: payYen < p.maxPayYen ? Math.floor(p.pointPercent / 100 * payYen) : p.pointMax
    }));
    const pointSum = promoObjArray.reduce(((pre, cur) => pre + cur.point), 0);

    return (
      <div className={styles.tooltip}>
        <p className={styles.sum}>{pointSum.toLocaleString()}㌽獲得 {payYen.toLocaleString()}円支払い時点</p>
        <hr className={styles.tooltipHr} />
        内訳
        {promoObjArray.map((o, i) => (
          <p key={o.promo.id} style={{ backgroundColor: o.promo.confirmed ? o.promo.color : hex2rgba(o.promo.color, 0.2) }}>
            {o.point.toLocaleString()}㌽ {o.promo.confirmed ? '' : '【未確定】'} {o.promo.name}
          </p>
        ))}
      </div>
    );
  }
}
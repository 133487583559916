import { memo } from 'react';
import { Bar, BarChart, CartesianGrid, Label, ReferenceLine, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import Promo, { YOUBI, parseTimeCaveat } from "./model/Promo";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import DailyPromo, { getDailyPromo } from "./model/DailyPromo";
import styles from './MainChart.module.css';
import hex2rgba from './thank/yoheim';


export default memo(function MainChart(
  {
    promoArray,
    setDailyPromo,
    year, month,
  }: {
    promoArray: Promo[],
    setDailyPromo: (d: DailyPromo) => void,
    year: number, month: number,
  }) {
  const dailyPromoArray = getDailyPromoArray();
  const chartData = createChartData(dailyPromoArray);

  let todayKey: string | null = null;
  const today = new Date();
  if ((today.getMonth() + 1) === month) {
    if (1 < chartData.length) {
      todayKey = chartData[today.getDate() - 1].date;
    }
  }

  console.debug('MainChart render.');
  return (
    <div>
      <div style={{ width: '100%', height: '800px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={chartData}
            layout="vertical"
            barCategoryGap="4%" margin={{ top: 20, right: 20, left: 20, bottom: 20 }} onClick={onClickChart}>
            <CartesianGrid horizontal={false} strokeDasharray="3 3" />
            <YAxis type="category" dataKey="date" label={{ value: year + '年' + month + '月', angle: -90, position: 'left' }} interval={0} />
            <XAxis type="number" label={{ value: 'ポイント還元率', position: 'top' }} tickFormatter={value => value + '%'} orientation="top" allowDecimals={false} tickCount={11} />
            <Tooltip /* <bar>より下にするとマウスオーバー時に表示がおかしくなる。 */
              content={({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
                return chartData.find(a => a.date === label)?.tooltip({ active, payload, label });
              }}
              cursor={{ stroke: 'pink', strokeWidth: 1 }}
            />
            {promoArray.filter(p => p.confirmed).map((p, i) => (
              <Bar
                key={p.id}
                dataKey={p.name}
                stackId='a'
                fill={p.color}
                stroke={p.color}
              />
            ))}
            {promoArray.filter(p => !p.confirmed).map(p => (
              <Bar
                key={p.id}
                dataKey={p.name}
                stackId='a'
                fill={p.color}
                stroke={p.color}
                fillOpacity={0.2}
                strokeOpacity={0.2}
              />
            ))}
            <Bar dataKey='kakuuKey' stackId='a' label={{ position: 'right', formatter: (value: string) => value + '%' }} />
            {todayKey ? <ReferenceLine y={todayKey} label={<Label value="今日" fill={'red'} fontSize={20} />} stroke="red" strokeDasharray="8 8" /> : null}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );

  function getDailyPromoArray(): DailyPromo[] {
    const monthDate: Date = new Date(year, -1 + month);
    const everydayDateArray = [];
    for (let dateIndex = 1; dateIndex <= 31; dateIndex++) {
      const date: Date = new Date(monthDate.getFullYear(), monthDate.getMonth(), dateIndex);
      if (monthDate.getMonth() !== date.getMonth()) {
        break;
      }
      everydayDateArray.push(date);
    }

    return everydayDateArray.map(date => getDailyPromo(date, promoArray));
  }

  function createChartData(dailyPromoArray: DailyPromo[]): ChartObj[] {

    return dailyPromoArray.map(dailyPromo => {
      const confirmedPromoArray = dailyPromo.promoArray.filter(p => p.confirmed).reverse();
      const unconfirmPromoArray = dailyPromo.promoArray.filter(p => !p.confirmed).reverse();
      const pointPercent = confirmedPromoArray.reduce((sum: number, p: Promo) => { return sum + p.pointPercent }, 0);
      const unconfirmedPointPercent = unconfirmPromoArray.reduce((sum: number, p: Promo) => { return sum + p.pointPercent }, 0);

      const timeCaveatArray = dailyPromo.promoArray.filter(p => p.timeCaveat).map(p => {
        const parsedTimeCaveatArray = parseTimeCaveat(p.timeCaveat);
        const msgArray = parsedTimeCaveatArray.filter(p => p.date === dailyPromo.date.getDate()).map(p => p.msg);
        return msgArray.map(m => m + '「' + p.name + '」');
      }).flat();

      const namePercentArray = dailyPromo.promoArray.map(p => ([p.name, p.pointPercent]));
      const dataObj1 = Object.fromEntries(namePercentArray);

      const dataObj2 = {
        'date': dailyPromo.date.getDate() + '(' + YOUBI[dailyPromo.date.getDay()] + ')',
        'pointPercent': pointPercent,
        'unconfirmedPointPercent': unconfirmedPointPercent,

        //次のtooltipはRechartsとしては不要。アプリで使う。
        'tooltip': ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
          return (
            <div className={styles.tooltip}>
              <div>
                <span>{month}/{label}</span>
                <span className={styles.totalPointPer}>{(pointPercent + unconfirmedPointPercent)}%</span>
              </div>

              <hr className={styles.tooltipHr} />

              {(1 <= timeCaveatArray.length) ?
                <>
                  <div>時間注意</div>
                  <ul className={styles.tooltipUl}>
                    {timeCaveatArray.map(t => <li key={t}>・{t}</li>)}
                  </ul>
                  <hr className={styles.tooltipHr} />
                </>
                : null
              }

              {(1 <= unconfirmPromoArray.length) ?
                <>
                  <p>【未確定】{unconfirmedPointPercent}%</p>
                  {unconfirmPromoArray.map(p => <p key={p.id} style={{ backgroundColor: hex2rgba(p.color, 0.2) }}>{p.pointPercent.toFixed(1)}% {p.name}</p>)}
                  <hr className={styles.tooltipHr} />
                </>
                : null
              }

              {(1 <= confirmedPromoArray.length) ?
                <>
                  <p>【確定】{pointPercent}%</p>
                  {confirmedPromoArray.map(p => <p key={p.id} style={{ backgroundColor: p.color }}>{p.pointPercent.toFixed(1)}% {p.name}</p>)}
                </>
                : null
              }
            </div>
          );
        }
      }
      return Object.assign(dataObj1, dataObj2);
    });
  }

  function onClickChart(anyData: any) {
    if (!anyData) {
      return;
    }
    const index = anyData.activeTooltipIndex;
    setDailyPromo(dailyPromoArray[index]);
  }
})

interface ChartObj {
  date: string;
  pointPercent: number;
  unconfirmedPointPercent: number;
  tooltip: any;
}

import { ChangeEvent, useEffect } from "react";
import Promo from "./model/Promo";
import { PromoDescDialog, PromosDescDialog } from "./PromoDescDialog";
import styles from "./InputForm.module.css";

export default function InputForm(
  {
    allPromoArray,
    selectablePromoArray,
    rank, setRank,
    selectedPromoIdArray, setSelectedPromoIdArray,
    setDailyPromo,
    year, month,
    monthId,
  }: {
    allPromoArray: Promo[],
    selectablePromoArray: Promo[],
    rank: string, setRank: (r: string) => void,
    selectedPromoIdArray: string[], setSelectedPromoIdArray: (a: string[]) => void,
    setDailyPromo: (d: undefined) => void,
    year: number, month: number,
    monthId: string,
  }) {

  const activePromoCount = selectablePromoArray.filter(p => 0 < p.pointPercent).filter(p => selectedPromoIdArray.includes(p.id)).length;

  const exclusiveLabelArray: string[] = [];

  useEffect(() => {
    setDailyPromo(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rank, selectedPromoIdArray]);

  console.debug('InputForm render.');
  return (
    <details open={false} className={styles.details}>
      <summary className={styles.summary}>
        {year + '年' + month + '月'}キャンペーン 選択済み: {activePromoCount}

      </summary>

      <div className={styles.inputForm}>
        <div >
          <div>あなたの楽天会員ランク</div>
          <div className={styles.rank}>
            {
              ['ダイヤモンド', 'プラチナ', 'ゴールド', 'シルバー', 'レギュラー'].map(r =>
                <span key={r}>
                  <input type='radio' value={r} id={monthId + 'rank' + r} name={monthId + 'rank'} onChange={onChangeRank} checked={r === rank} />
                  <label htmlFor={monthId + 'rank' + r}>{r}</label>
                </span>
              )
            }
          </div>
        </div>

        {Array.from(selectablePromoArray).map(p => {
          if (p.exclusiveLabel) {
            if (exclusiveLabelArray.includes(p.exclusiveLabel)) {
              return null;
            }
            exclusiveLabelArray.push(p.exclusiveLabel);
            return <div key={p.exclusiveLabel} className={styles.promoItem}>
              <div>
                <select className={styles.selectable}
                  onChange={onSelectPromoOfGroup}
                  value={selectablePromoArray.find(pp => pp.exclusiveLabel === p.exclusiveLabel && selectedPromoIdArray.includes(pp.id))?.id} >
                  {
                    selectablePromoArray.filter(pp => pp.exclusiveLabel === p.exclusiveLabel).map(pp =>
                      <option key={pp.id} value={pp.id}>
                        {pp.pointPercent.toFixed(1) + '% ' + (p.confirmed ? '' : '【未確定】') + pp.name}
                      </option>
                    )
                  }
                </select>
              </div>
              <div><PromosDescDialog promoArray={selectablePromoArray.filter(pp => pp.exclusiveLabel === p.exclusiveLabel)}></PromosDescDialog></div>
            </div>
          } else {
            return <div key={p.id} className={styles.promoItem}>
              <div>
                <input type='checkbox' className={styles.selectable}
                  value={p.name}
                  id={monthId + 'promo' + p.id}
                  checked={selectedPromoIdArray.includes(p.id)}
                  onChange={onSelectPromo}
                />
                <label htmlFor={monthId + 'promo' + p.id} className={styles.selectable}>
                  {p.pointPercent.toFixed(1) + '%  ' + (p.confirmed ? '' : '【未確定】') + p.name}
                </label>
              </div>
              <div><PromoDescDialog promo={p} /></div>
            </div>
          }
        }
        )}
      </div>
    </details>
  );

  function onChangeRank(event: ChangeEvent<HTMLInputElement>) {
    const newRank = event.target.value;
    setRank(newRank);
  }

  function onSelectPromoOfGroup(event: ChangeEvent<HTMLSelectElement>) {
    const promoId = event.target.value;
    let newIdArray = selectedPromoIdArray.concat();

    const promo = selectablePromoArray.find(p => p.id === promoId);
    if (!promo) return;

    const label = promo.exclusiveLabel;
    const sameLabelIdArray = selectablePromoArray.filter(p => p.exclusiveLabel === label).map(p => p.id);
    newIdArray = newIdArray.filter(id => !sameLabelIdArray.includes(id));

    newIdArray.push(promoId);
    newIdArray = Array.from(new Set(newIdArray));
    setSelectedPromoIdArray(newIdArray);
  }

  function onSelectPromo(event: ChangeEvent<HTMLInputElement>) {
    const name = event.target.value;
    // 同名のキャンペーンにも反映する。別ランクの同名キャンペーンを想定。
    const sameNameIdArray = allPromoArray.filter(p => p.name === name).map(p => p.id);

    let newIdArray = selectedPromoIdArray.concat();
    if (event.target.checked) {
      newIdArray = newIdArray.concat(sameNameIdArray);
      newIdArray = Array.from(new Set(newIdArray));
    } else {
      newIdArray = newIdArray.filter(id => !sameNameIdArray.includes(id));
    }
    setSelectedPromoIdArray(newIdArray);
  }

}
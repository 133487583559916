// 次のソースをコピーした。。。 https://www.yoheim.net/blog.php?q=20171007
/**
 * hexカラーコード定義をrgbaに変換する.
 */
export default function hex2rgba(hex: string | undefined, alpha = 1) {
  if (hex === undefined) {
    return 'rgba(0,0,0,1)'
  }

  // ロングバージョンの場合（例：#FF0000）
  let r = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i)
  let c = null
  if (r) {
    c = r.slice(1, 4).map(function (x) { return parseInt(x, 16) })
  }
  // ショートバージョンの場合（例：#F00）
  r = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i)
  if (r) {
    c = r.slice(1, 4).map(function (x) { return 0x11 * parseInt(x, 16) })
  }
  // 該当しない場合は、nullを返す.rgba(0,0,0,1)を返す。
  if (!c) {
    return 'rgba(0,0,0,1)'
  }
  return `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${alpha})`
}

import Promo, { toEasyPeriod, toEasyTimeCaveat } from './model/Promo';
import './PromoDescDialog.css';

function PromoDescDialog(
  {
    promo
  }: {
    promo: Promo
  }) {

  return PromosDescDialog({ promoArray: [promo] });
}

function PromosDescDialog(
  {
    promoArray
  }: {
    promoArray: Promo[]
  }) {
  return (
    <>
      <button onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const button = event.currentTarget;
        const dialog = button.nextElementSibling as HTMLDialogElement;
        if (dialog.open) {
          dialog.close();
        } else {
          dialog.showModal();
        }
      }}>詳細</button>

      {/* 上のbuttonとdialogの間に要素を入れてはならない。隣り合うことを前提にしたコーディングのため。*/}
      <dialog onClick={(event: React.MouseEvent<HTMLDialogElement, MouseEvent>) => {
        const dialog = event.currentTarget;

        const dialogPos = dialog.getBoundingClientRect();
        if (event.clientX < dialogPos.left || dialogPos.right < event.clientX ||
          event.clientY < dialogPos.top || dialogPos.bottom < event.clientY) {
          dialog.close();
        }
      }}>
        <div style={{ textAlign: 'right' }}>
          <span
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              const dialog = event.currentTarget.closest('dialog') as HTMLDialogElement;
              dialog.close();
            }}
            style={{ cursor: 'pointer' }}
          >❌</span>
        </div>

        {promoArray.map(promo =>
          <div key={promo.id}>
            <div className="promoName">{(promo.confirmed ? '' : '【未確定】') + promo.name}</div>
            <table>
              <tbody>
                {rowText('注意事項', promo.caveat)}
                {rowText('ポイント加算', promo.pointPercent.toFixed(1) + '%')}
                {promo.pointMax ? rowText('上限ポイント', promo.pointMax.toLocaleString() + '㌽') : null}
                {promo.maxPayYen ? rowText('上限到達支払い', promo.maxPayYen.toLocaleString() + '円※税抜') : null}
                {rowText('条件', promo.condition)}
                {rowText('ポイント計算', promo.tax)}
                {rowText('付与㌽種別', promo.pointType)}
                {rowText('対象日', toEasyPeriod(promo.period) + (promo.timeCaveat ? ' #' + toEasyTimeCaveat(promo.timeCaveat) : ''))}
                {rowText('獲得予定表示名', promo.displayName)}
                {rowText('㌽払いで', promo.pointPoint)}
                {rowLink('公式', promo.url)}
              </tbody>
            </table>
          </div>
        )}
      </dialog>
    </>
  );
}

function rowText(label: string, value: string) {
  if (!value) {
    return null;
  }
  value = value.trim();
  if (value.length === 0) {
    return null;
  }

  return <tr>
    <td className="itemItem">{label}</td>
    <td className="itemValue">{value}</td>
  </tr>
}

function rowLink(label: string, url: string, linkText: string = 'キャンペーンページを開く') {
  if (!url || url.length === 0) {
    return null;
  }
  return <tr>
    <td className="itemItem">{label}</td>
    <td className="itemValue"><a href={url} target='_blank' rel='noreferrer'>{linkText}</a></td>
  </tr>
}

export { PromoDescDialog, PromosDescDialog };

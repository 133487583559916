export default interface Promo {
	id: string;
	name: string;
	confirmed: boolean;
	rank: string;
	exclusiveLabel: string;
	default: boolean;
	pointPercent: number;
	pointMax: number;
	tax: string;
	pointType: string;
	maxPayYen: number;
	period: string;
	condition: string;
	timeCaveat: string;
	caveat: string;
	pointPoint: string;
	visible: boolean;
	displayName: string;
	url: string;
	memo: string;

	color: string;
}

export const YOUBI = ['日', '月', '火', '水', '木', '金', '土'];

export function toEasyPeriod(period: string): string {

	return period.split(',').map(v => {
		if (YOUBI.includes(v)) {
			return v + '曜'
		}
		const day = Number(v);
		if (day) {
			return day + '日';
		} else {
			return v;
		}
	}).join(',');
}

export function toEasyTimeCaveat(timeCaveat: string): string {
	const pasedArray = parseTimeCaveat(timeCaveat);
	return pasedArray.map(p => p.date + '日は' + p.msg).join(', ');
}

export function parseTimeCaveat(timeCaveat: string): { date: number; msg: string }[] {
	return timeCaveat
		.split('\n')
		.map(s => {
			const sArray = s.split(':');
			const date = Number(sArray[0]);
			const msg = sArray[1];
			return { date, msg };
		});
}

import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import Zpp from './Zpp';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  /*
  <React.StrictMode>
  */
    <BrowserRouter>
      <Routes>
        <Route path="/app" element={<App />} />
        <Route path="/zpp" element={<Zpp />} />;

        <Route path="/" element={<Link to="/app">app</Link>} />
        <Route path="*" element={<h1>404 Not Found.</h1>} />
      </Routes>
    </BrowserRouter>
  /*
  </React.StrictMode>
  */
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import Promo, { YOUBI } from "./Promo";

export default interface DailyPromo {
  date: Date;
  promoArray: Promo[];
}

export function getDailyPromo(date: Date, candidatePromoArray: Promo[]): DailyPromo {
  const dailyPromoArray: Promo[] = candidatePromoArray.filter((promo) => {
    // periodのパターンサンプルは以下。
    // 毎日
    // 18
    // 5,10,15,20,25,30
    // 火,木
    if (promo.period === '毎日') {
      return true;
    }
    const valueArray: string[] = promo.period.split(',');
    if (valueArray.includes(date.getDate().toString()) || valueArray.includes(YOUBI[date.getDay()])) {
      return true;
    }
    return false;
  });

  return { date, promoArray: dailyPromoArray };
}
import { Link } from "react-router-dom";
import { siteName } from "./Site";
import styles from './Header.module.css';

function Header() {

    return (
        <header>
            <Link to="/app" className={styles.siteName}>{siteName}</Link>
        </header>
    );
}

export default Header;